@media (max-width: 768.98px) {
  .scholarship-header {
    padding: 24px 20px; } }

.scholarship-header__wrapper {
  background: #1E5558; }

.scholarship-header__top-wrapper {
  background: rgba(0, 0, 0, 0.2); }
  @media (max-width: 768.98px) {
    .scholarship-header__top-wrapper {
      background: transparent; } }

.scholarship-header__top {
  display: flex;
  align-items: center;
  padding: 18px;
  max-width: 1224px;
  margin: 0 auto; }
  @media (max-width: 768.98px) {
    .scholarship-header__top {
      justify-content: flex-end;
      padding: 0; } }

.scholarship-header__status {
  margin-left: 23px; }

.scholarship-header__content {
  padding: 32px 0px 1px 0px;
  max-width: 1168px;
  margin: 0 auto;
  position: relative; }
  .scholarship-header__content .scholarship-header__back-move .transparent-CTAButton {
    position: absolute;
    left: -149px;
    top: 96px; }
    @media (max-width: 1279.98px) {
      .scholarship-header__content .scholarship-header__back-move .transparent-CTAButton {
        left: unset;
        right: 0;
        top: 0; } }
  .scholarship-header__content .scholarship-logo-container {
    width: 200px;
    height: 200px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #1a282c;
    margin-bottom: -32px;
    position: relative; }
    @media (max-width: 768.98px) {
      .scholarship-header__content .scholarship-logo-container {
        width: 150px;
        height: 150px;
        margin-bottom: -45px; } }
  @media (max-width: 1023.98px) {
    .scholarship-header__content {
      padding: 32px 15px 72px 15px; } }
  @media (max-width: 768.98px) {
    .scholarship-header__content {
      padding: 0; } }

.scholarship-header__logo {
  height: 150px;
  width: 150px;
  object-fit: contain; }
  @media (max-width: 768.98px) {
    .scholarship-header__logo {
      margin-bottom: 18px;
      height: 100px;
      width: 100px; } }

.scholarship-header__name {
  font-family: "Inter-Regular";
  font-size: 36px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  margin-bottom: 50px; }
  .scholarship-header__name.small-card-title {
    font-size: 18px;
    font-family: 'Inter-Medium';
    margin-bottom: 35px; }
  @media (max-width: 768.98px) {
    .scholarship-header__name {
      padding-bottom: 51px;
      border-bottom: solid 1px rgba(255, 255, 255, 0.2);
      margin-bottom: 18px; } }

.scholarship-header__award-info {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.scholarship-header__award {
  font-family: "Inter-Medium";
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: white; }

.scholarship-header__date {
  font-family: "Inter-Medium";
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #f6f6f6; }

.preview-scholarship-header {
  padding-top: 0 !important; }
