.bg-white {
  background-color: #ffffff; }

.scholarship-body__card {
  padding: 0;
  border-radius: 10px;
  margin-bottom: 32px;
  min-width: 400px;
  max-width: 400px;
  background: #ffffff;
  border: 1px solid #1a282c; }
  @media (max-width: 768.98px) {
    .scholarship-body__card {
      min-width: unset;
      max-width: unset; } }
  .scholarship-body__card-box {
    padding: 20px 20px 20px; }
  .scholarship-body__card-bar {
    padding: 8px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom: 1px solid #000000; }
  .scholarship-body__card-top, .scholarship-body__card-save {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .scholarship-body__card-top svg, .scholarship-body__card-save svg {
      cursor: pointer; }
  .scholarship-body__card .small-card-logo img {
    width: 120px;
    height: 120px;
    object-fit: contain;
    margin-bottom: 20px; }
  .scholarship-body__card .small-card-logo svg {
    width: 120px;
    height: 120px;
    object-fit: contain;
    margin-bottom: 20px; }
  .scholarship-body__card-top {
    margin-bottom: 35px;
    background: #EFEFE8;
    padding: 10px 10px;
    border-radius: 10px; }
  .scholarship-body__card-deadline {
    display: flex;
    align-items: center; }
  .scholarship-body__card .share-and-status-button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px; }
    .scholarship-body__card .share-and-status-button-container .share-container {
      display: flex;
      align-items: center;
      font-family: "Inter-SemiBold" !important;
      cursor: pointer; }
      .scholarship-body__card .share-and-status-button-container .share-container svg {
        margin-right: 10px; }
    .scholarship-body__card .share-and-status-button-container .button-container .scholarship-body__card-cta {
      margin: 0px 0px 0px;
      padding: 5px 20px;
      line-height: 1.3; }
  .scholarship-body__card-deadline-label {
    font-family: "Inter-Medium";
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #434445; }
  .scholarship-body__card-meta {
    margin-bottom: 16px; }
  .scholarship-body__card-cta {
    margin: 18px 0 16px;
    text-align: center;
    padding: 20px;
    width: 100%;
    border-radius: 5px;
    background: #1C21DF;
    display: flex;
    align-items: center;
    justify-content: center; }
  .scholarship-body__card-meta-label {
    display: block;
    text-transform: uppercase;
    opacity: 0.7;
    font-family: "Inter-Medium";
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: black; }
  .scholarship-body__card-meta-info {
    line-height: 2.29;
    letter-spacing: 0.31px; }

.scholarship-body__card-mobile .mobile-length-and-est-time-container {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.scholarship-body__card-mobile .mobile-apply-now-button {
  margin-bottom: 30px; }

.scholarship-body__card-mobile .mobile-become-a-sponsor {
  margin-bottom: 30px; }

.scholarship-body__feature {
  margin-bottom: 69px; }
  .scholarship-body__feature .html-from-salesforce ul {
    list-style-type: disc;
    margin: 1em;
    margin-left: 40px; }
  .scholarship-body__feature .html-from-salesforce b {
    font-weight: bold; }
  .scholarship-body__feature .html-from-salesforce i {
    font-style: italic; }
  .scholarship-body__feature .html-from-salesforce u {
    text-decoration: underline; }
  .scholarship-body__feature .html-from-salesforce a {
    color: #1C21DF;
    text-decoration: underline; }
  .scholarship-body__feature .html-from-salesforce ol {
    list-style-type: auto;
    margin: 1em;
    margin-left: 40px; }
    .scholarship-body__feature .html-from-salesforce ol ol {
      list-style: lower-alpha; }
      .scholarship-body__feature .html-from-salesforce ol ol ol {
        list-style: lower-roman; }
  .scholarship-body__feature .html-from-salesforce table thead th {
    font-weight: bold; }
  .scholarship-body__feature-top {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .scholarship-body__feature-top__icon-with-title {
      display: flex;
      align-items: center; }
  .scholarship-body__feature-name {
    display: inline-block;
    text-transform: uppercase; }
  .scholarship-body__feature-body {
    margin-left: auto; }
  .scholarship-body__feature-divider {
    margin: 23.5px 0 20.5px auto; }
  .scholarship-body__feature-single {
    margin-bottom: 24px; }
    .scholarship-body__feature-single:last-child {
      margin-bottom: 0; }
  .scholarship-body__feature-label {
    margin-left: 16px; }

.scholarship-body__leading {
  width: 100%;
  max-width: 693px; }
  .scholarship-body__leading .scholarship-application-accepting-status {
    margin-bottom: 30px;
    display: flex;
    align-items: center; }
    .scholarship-body__leading .scholarship-application-accepting-status .status-value {
      background: #F7F7F3;
      color: #1a282c;
      padding: 5px 8px;
      border-radius: 5px;
      font-family: "Inter-Medium";
      text-transform: uppercase; }
    .scholarship-body__leading .scholarship-application-accepting-status .share-application {
      font-family: "Inter-SemiBold" !important;
      margin-left: 0px;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer; }
      .scholarship-body__leading .scholarship-application-accepting-status .share-application svg {
        margin-right: 10px; }
  .scholarship-body__leading #scholarship-video {
    max-width: 635px;
    margin: 20px 10px;
    overflow: auto; }

.scholarship-body__tag {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 4px;
  background: #f6f6f6;
  margin-right: 8px;
  margin-bottom: 8px; }
  .scholarship-body__tag:last-child {
    margin-right: 0; }
  @media (max-width: 768.98px) {
    .scholarship-body__tag {
      border: solid 1px #ebebeb; } }

.scholarship-body__tags {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-bottom: 66px; }

.scholarship-body__description {
  margin: 24px 0 66px auto; }
  .scholarship-body__description .html-from-salesforce img {
    max-width: 100%; }
  .scholarship-body__description .html-from-salesforce ul {
    list-style-type: disc;
    margin: 1em;
    margin-left: 40px; }
  .scholarship-body__description .html-from-salesforce b {
    font-weight: bold; }
  .scholarship-body__description .html-from-salesforce i {
    font-style: italic; }
  .scholarship-body__description .html-from-salesforce u {
    text-decoration: underline; }
  .scholarship-body__description .html-from-salesforce a {
    color: #1C21DF;
    text-decoration: underline; }
  .scholarship-body__description .html-from-salesforce ol {
    list-style-type: auto;
    margin: 1em;
    margin-left: 40px; }
    .scholarship-body__description .html-from-salesforce ol ol {
      list-style: lower-alpha; }
      .scholarship-body__description .html-from-salesforce ol ol ol {
        list-style: lower-roman; }
  .scholarship-body__description .html-from-salesforce table thead th {
    font-weight: bold; }
  .scholarship-body__description table tbody td {
    white-space: unset; }

.scholarship-body__sponsor-link {
  display: block;
  text-align: center; }

.scholarship-body__content {
  display: flex;
  justify-content: space-between;
  max-width: 1168px;
  padding: 90px 20px;
  margin: 0 auto; }
  @media (max-width: 1023.98px) {
    .scholarship-body__content {
      flex-direction: column; } }
  @media (max-width: 768.98px) {
    .scholarship-body__content {
      flex-direction: column; } }

.scholarship-body__gpa-circle {
  border-radius: 50%;
  display: inline-block;
  width: 16px;
  height: 16px;
  box-shadow: -1px 1px 0 0 #1C21DF;
  border: solid 1.5px #1C21DF;
  background-color: white; }

.scholarship-body__question {
  padding-bottom: 36px;
  border-bottom: solid 1px #f0f2f2;
  margin-bottom: 35px; }
  .scholarship-body__question:last-child {
    border-bottom: none;
    margin-bottom: 0; }
  .scholarship-body__question-label {
    display: block;
    margin-bottom: 16px; }
  .scholarship-body__question-words {
    font-family: "Inter-Medium";
    font-size: 11px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-transform: uppercase;
    padding: 8px 12px;
    border-radius: 5px;
    background: #f6f6f6; }

.scholarship-body__sponsor-main {
  background-color: #f6f6f6;
  margin-bottom: 50px; }

.scholarship-body__sponsor {
  max-width: 1133px;
  margin: 0 auto; }
  @media (max-width: 768.98px) {
    .scholarship-body__sponsor {
      padding: 37px 20px 64px; } }
  .scholarship-body__sponsor-content {
    display: flex;
    justify-content: space-between;
    gap: 20px; }
    @media (max-width: 1023.98px) {
      .scholarship-body__sponsor-content {
        flex-direction: column-reverse; } }
    @media (max-width: 768.98px) {
      .scholarship-body__sponsor-content {
        flex-direction: column-reverse; } }
  .scholarship-body__sponsor-description {
    margin: 15px 0 15px; }
    @media (max-width: 768.98px) {
      .scholarship-body__sponsor-description {
        margin: 24px 0 27px; } }
  .scholarship-body__sponsor-info {
    max-width: 540px; }
    .scholarship-body__sponsor-info .scholarship-body__sponsor-logo {
      width: 150px;
      object-fit: cover; }
    .scholarship-body__sponsor-info ol {
      list-style-type: auto;
      margin: 1em;
      margin-left: 40px; }
      .scholarship-body__sponsor-info ol ol {
        list-style: lower-alpha; }
        .scholarship-body__sponsor-info ol ol ol {
          list-style: lower-roman; }
    .scholarship-body__sponsor-info ul {
      list-style-type: disc;
      margin: 1em;
      margin-left: 40px; }
    .scholarship-body__sponsor-info img {
      max-width: 100%; }
    .scholarship-body__sponsor-info a {
      color: #1C21DF; }
    .scholarship-body__sponsor-info .ql-indent-1 {
      text-indent: 3em; }
    .scholarship-body__sponsor-info .ql-indent-2 {
      text-indent: 6em; }
    .scholarship-body__sponsor-info .ql-indent-3 {
      text-indent: 9em; }
    .scholarship-body__sponsor-info .ql-indent-4 {
      text-indent: 12em; }
    .scholarship-body__sponsor-info .ql-indent-5 {
      text-indent: 15em; }
    .scholarship-body__sponsor-info .ql-indent-6 {
      text-indent: 18em; }
    .scholarship-body__sponsor-info .ql-indent-7 {
      text-indent: 21em; }
    .scholarship-body__sponsor-info .ql-indent-8 {
      text-indent: 24em; }
    .scholarship-body__sponsor-info .ql-indent-9 {
      text-indent: 27em; }
    .scholarship-body__sponsor-info .ql-indent-10 {
      text-indent: 30em; }
    .scholarship-body__sponsor-info .ql-indent-11 {
      text-indent: 33em; }
    .scholarship-body__sponsor-info .ql-indent-12 {
      text-indent: 36em; }
    .scholarship-body__sponsor-info .ql-indent-13 {
      text-indent: 39em; }
    @media (max-width: 1023.98px) {
      .scholarship-body__sponsor-info {
        max-width: 315px; } }
    @media (max-width: 768.98px) {
      .scholarship-body__sponsor-info {
        max-width: unset; } }
  .scholarship-body__sponsor-logo {
    display: block;
    max-height: 150px;
    max-width: 150px; }
  .scholarship-body__sponsor-heading {
    text-transform: uppercase;
    margin-bottom: 0px;
    margin-top: 15px; }
    @media (max-width: 768.98px) {
      .scholarship-body__sponsor-heading {
        margin-bottom: 32px; } }
  .scholarship-body__sponsor-img-wrapper {
    position: relative;
    width: 100%;
    max-width: 500px;
    height: 400px;
    min-width: 500px; }
    @media (max-width: 768.98px) {
      .scholarship-body__sponsor-img-wrapper {
        max-width: 100%;
        height: 330px;
        margin-top: 58px;
        min-width: unset; } }
  .scholarship-body__sponsor-img {
    display: block;
    position: absolute;
    width: 100%;
    max-width: 500px;
    max-height: 450px;
    object-fit: cover;
    border-radius: 15px; }
    @media (max-width: 768.98px) {
      .scholarship-body__sponsor-img {
        max-width: 100%;
        max-height: 330px; } }
  .scholarship-body__sponsor-bottom {
    display: flex; }
  .scholarship-body__sponsor-social {
    margin-top: 15px; }
    .scholarship-body__sponsor-social a {
      border: 0px solid;
      border-radius: 30px;
      margin-right: 5px;
      display: inline-flex; }
  .scholarship-body__sponsor-highlight {
    margin-right: 98px; }
    @media (max-width: 768.98px) {
      .scholarship-body__sponsor-highlight {
        margin-right: 60px; } }
    .scholarship-body__sponsor-highlight:last-child {
      margin-right: 0; }
  .scholarship-body__sponsor-highlight-label {
    margin-top: 5px;
    display: block;
    font-family: "Inter-Medium";
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1a282c; }

.scholarship-body__card-cta.begin-with-google .cta-button__text {
  display: flex;
  align-items: center; }
  .scholarship-body__card-cta.begin-with-google .cta-button__text svg {
    margin-right: 10px; }

.scholarship__landing__page {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  height: 65px; }

.scholarship__tile__content {
  min-height: 50px; }

.scholarship-card__donations-progress-bar__donations-amount-status {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between; }

.goal__scholarship {
  font-size: 20px !important; }

.donation-goal-scholarship-tile {
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: bold; }
  .donation-goal-scholarship-tile span {
    font-size: 20px; }

.scholarship-body__card.mobile-view {
  min-width: unset; }

.share-url-modal {
  min-width: 700px; }
  @media (max-width: 479.98px) {
    .share-url-modal {
      min-width: 90% !important; } }

.sweet-alert {
  text-align: unset !important;
  border: 1px solid #1a282c; }

.action-footer {
  display: flex;
  justify-content: end !important;
  height: 25px; }

.action-footer button:focus {
  border: 2px solid #1E5558; }

.sweet-alert > div {
  text-align: unset !important; }
