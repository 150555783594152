#after-loged-out-page-content .applicant-scholarship .scholarship-header__wrapper {
  background-color: #1E5558; }
  #after-loged-out-page-content .applicant-scholarship .scholarship-header__wrapper .scholarship-header {
    padding-top: 80px; }
    @media (max-width: 768.98px) {
      #after-loged-out-page-content .applicant-scholarship .scholarship-header__wrapper .scholarship-header {
        padding-top: 24px; } }

.applicant-scholarship .scholarship-body__sponsor {
  padding: 85px 0px 85px 0px; }
  @media (max-width: 1023.98px) {
    .applicant-scholarship .scholarship-body__sponsor {
      padding: 20px 20px 20px 20px; } }
  @media (max-width: 768.98px) {
    .applicant-scholarship .scholarship-body__sponsor {
      padding: 20px 20px 20px 20px; } }

.applicant-scholarship .scholarship-recommendation {
  padding: 118px 0px 80px 0px;
  background: #f6f6f6; }
  @media (max-width: 768.98px) {
    .applicant-scholarship .scholarship-recommendation {
      padding: 40px 20px 40px 40px; } }
  .applicant-scholarship .scholarship-recommendation .scholarship-recommendation__title {
    margin-bottom: 66px; }
