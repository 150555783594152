.footer {
  background: #1a282c; }
  .footer .container .footer-top {
    grid-gap: 40px;
    display: flex;
    gap: 40px;
    font-family: "Inter-Regular", sans-serif;
    padding-top: 80px;
    padding-bottom: 30px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto; }
    .footer .container .footer-top .footer-col {
      flex-basis: 25%; }
    @media screen and (max-width: 1024px) {
      .footer .container .footer-top {
        justify-content: space-between;
        margin-left: 20px; } }
    @media (max-width: 479.98px) {
      .footer .container .footer-top {
        display: block;
        margin-left: 0px; } }
    @media (max-width: 768.98px) {
      .footer .container .footer-top {
        display: block;
        margin-left: 0px; }
        .footer .container .footer-top .footer-top__column-one {
          padding-bottom: 30px; } }
    @media screen and (max-width: 768.98px) and (max-width: 1024px) {
      .footer .container .footer-top .footer-top__column-one {
        padding: 0 20px;
        margin-bottom: 34px; } }
    @media screen and (max-width: 1024px) {
      .footer .container .footer-top .footer-top__column-three {
        padding: 0 20px; } }
    @media screen and (max-width: 1024px) {
      .footer .container .footer-top .footer-top__column-two {
        padding: 0 20px; } }
    @media screen and (max-width: 1024px) {
      .footer .container .footer-top .footer-top__column-four {
        padding: 0 20px; } }
    .footer .container .footer-top .kaleidoscope-footer-logo {
      margin-bottom: 15px; }
    .footer .container .footer-top .copyright-small {
      color: #ffffff;
      font-family: "Inter-Regular", sans-serif;
      margin-bottom: 45px;
      font-size: 14px; }
    .footer .container .footer-top .footer-buttons {
      margin-top: 15px;
      display: flex; }
      .footer .container .footer-top .footer-buttons .contact-us-btn {
        background: #ebebeb;
        padding: 10px 15px;
        margin-right: 10px;
        border-radius: 5px;
        color: #1C21DF; }
      .footer .container .footer-top .footer-buttons .login-btn {
        background: #ebebeb;
        padding: 10px 15px;
        border-radius: 5px;
        color: #1C21DF; }
    .footer .container .footer-top .footer-column-heading {
      color: #f7cac8;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 25px;
      letter-spacing: 0.75px; }
    .footer .container .footer-top .footer-column-list li {
      margin-bottom: 30px; }
      .footer .container .footer-top .footer-column-list li:hover a {
        color: #f7cac8; }
      .footer .container .footer-top .footer-column-list li a {
        color: #fff;
        font-size: 18px;
        font-family: "Inter-Regular", sans-serif;
        font-weight: 400; }
  .footer .container .footer-bottom {
    grid-gap: 40px;
    display: flex;
    gap: 40px;
    font-family: "Inter-Regular", sans-serif;
    padding-top: 80px;
    padding-bottom: 30px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    position: relative; }
    @media screen and (max-width: 1024px) {
      .footer .container .footer-bottom {
        display: block;
        margin-left: 14px;
        padding-top: 50px;
        padding-bottom: 100px; } }
    .footer .container .footer-bottom__left-section {
      flex-basis: 50%;
      padding-bottom: 30px; }
      .footer .container .footer-bottom__left-section .first-badge {
        width: 56px;
        margin-right: 10px; }
      .footer .container .footer-bottom__left-section .second-badge {
        width: 50px; }
    .footer .container .footer-bottom__right-section {
      position: absolute;
      right: -3.1rem; }
      @media screen and (max-width: 1024px) {
        .footer .container .footer-bottom__right-section {
          right: auto !important; } }
      @media (max-width: 479.98px) {
        .footer .container .footer-bottom__right-section {
          position: none !important; } }
      .footer .container .footer-bottom__right-section .footer-navigation {
        display: flex;
        font-family: "Inter-Regular", sans-serif;
        font-size: 14px;
        text-align: right;
        justify-content: right; }
        .footer .container .footer-bottom__right-section .footer-navigation li {
          border-left: 2px solid #ccc;
          padding: 0 10px; }
          .footer .container .footer-bottom__right-section .footer-navigation li:hover a {
            color: #f7cac8; }
          .footer .container .footer-bottom__right-section .footer-navigation li a {
            color: #fff; }
        .footer .container .footer-bottom__right-section .footer-navigation li:first-child {
          border-left: none;
          padding-left: 0; }
  .footer hr {
    border-bottom: "1px solid #efefe8";
    margin: 0;
    opacity: 0.5; }
