.share-report-modal-content {
  max-width: 715px;
  padding: 15px 10px 30px 10px; }
  .share-report-modal-content .share-report-head_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center; }
    .share-report-modal-content .share-report-head_container #share-report__title {
      text-align: center;
      position: relative; }
    @media (max-width: 479.98px) {
      .share-report-modal-content .share-report-head_container .share {
        display: none; } }
  .share-report-modal-content .main-content {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: end; }
    @media (max-width: 479.98px) {
      .share-report-modal-content .main-content {
        display: unset; } }
    .share-report-modal-content .main-content .main-content__link {
      margin-right: 8px;
      font-family: 'Inter-Medium';
      border: 1px solid gray;
      padding: 10px;
      border-radius: 5px;
      line-break: anywhere; }
      @media (max-width: 479.98px) {
        .share-report-modal-content .main-content .main-content__link {
          margin-bottom: 10px; } }
    .share-report-modal-content .main-content .main-content__button {
      background-color: #ffffff;
      border: 1px solid #1C21DF;
      color: #1C21DF;
      border-radius: 5px;
      justify-content: center;
      min-width: 140px;
      font-family: 'Inter-Medium'; }
  .share-report-modal-content .main-title {
    font-family: 'Inter-Medium';
    font-size: 18px;
    padding: 20px 0 10px 0;
    font-weight: 400; }
